import { apiError, DO_LOGIN } from ".";

export async function doLogin(email, password) {
  try {
    const body = { email, password };
    const response = await fetch(DO_LOGIN, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (err) {
    return apiError(err.message, err.stack, "doLogin");
  }
}
