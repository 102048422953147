/*
const USERS_API =
  process.env.NODE_ENV === "production"
    ? "users"
    : "http://localhost:5000/users";
*/

// const USERS_API = "localhost:5000/users"

/*const USERS_API =
  process.env.NODE_ENV === "production"
    ? "localhost:5000/users"
    : "http://localhost:5000/users";
*/

const USERS_API = "https://gistarc.unicampania.it:5000/users"

export const CREATE_USER = `${USERS_API}/add-user`;
export const READ_USER = `${USERS_API}/get-user-by-id`;
export const UPDATE_USER = `${USERS_API}/update-user-by-id`;
export const DELETE_USER = `${USERS_API}/delete-user-by-id`;

export const GET_ALL_USERS = `${USERS_API}/get-all-users`;

export const DO_LOGIN = `${USERS_API}/login`;

export const READ_USER_FROM_TOKEN = `${USERS_API}/read-user-from-token`;
export const IS_GOOD_TOKEN = `${USERS_API}/is-auth`;

export const GET_ROLES = `${USERS_API}/get-roles`;

export const apiError = (err_message, err_stack, func) => {
  return {
    success: false,
    type: "client-api",
    error: err_message,
    stack: err_stack,
    function: "client-api: " + func,
  };
};
